import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { textAppearAnimationAll } from "../components/textAppearAnimation"
import TextRepeater from "../components/TextRepeater"
import Newsletter from "../components/newsletter"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ClicableVideoSection from "../components/works/clicableVideoSection"
import parse from "html-react-parser"
import VideoEmbed from "../components/VideoEmbed"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const titlesList = [
  'Creative',
  'Advertising',
  'Film Production',
  'Post Production',
  'Motion graphics',
  'Social ads'
]

let videoInterval = false;
let videoFrames = 129;

let currentVideoFrame = 1;
let currentTitleItem = 0;

const HomePage = ({ data, location }) => {

  const siteTitle = data.site.siteMetadata.title;
  const content = data.wpPage;

  const workEntry = data.allWpWork.nodes;
  const offer = content.customFieldsHomepage.offer;
  const faq = content.customFieldsHomepage.faq;

  const [isVideoLoaded, videoLoading] = useState(false);
  const [videoIsVisible, playVideo] = useState(false);

  const startLoadImages = () => {
      
      let height = window.innerHeight;
      let sloganHeight = document.querySelector('.hero-section-homepage').getBoundingClientRect().height;

      let topMargin = 0; //window.innerWidth > 568 ? 200 : 138;

      document.getElementById('homepage-video-event').style.height = height + 'px';
      document.getElementById('homepage-video-event-content').style.height = height + 'px';
      // document.querySelector('.hero-section-homepage').style.marginBottom = (height - sloganHeight - topMargin) + 'px';
      
      for(let i = 1; i < videoFrames; i++)
      {
        let curImg = new Image();
        const path = 'video/hover-' + i +'.jpg'
        curImg.src = path;
        curImg.id = "video-frame-" + i;
        curImg.onload = function() {
          document.getElementById('homepage-video-event').append(curImg);
        }
      }

  }

  useEffect( () => {

    textAppearAnimationAll('h1');
    textAppearAnimationAll('h2.animation');
    //textAppearAnimationAll('h3');

    if(!isVideoLoaded) {
      startLoadImages();
      videoLoading(true);
    }

    if(videoIsVisible){
      videoInterval = setInterval(() => {
        let lastFrame = document.querySelector('#video-frame-' + currentVideoFrame);
        lastFrame.classList.remove('visible');

        currentVideoFrame++;
        if(currentVideoFrame >= videoFrames){
          currentVideoFrame = 1;
        }

        if(!(currentVideoFrame % 4)) {
          let title = document.querySelector('#title-id');
          title.innerHTML = titlesList[currentTitleItem];
          currentTitleItem++;
          if(currentTitleItem >= titlesList.length){
            currentTitleItem = 0;
          }
        }

        let elem = document.querySelector('#video-frame-' + currentVideoFrame);
        elem.classList.add('visible');

      }, 60)
    } else {

      let elems = document.querySelectorAll(".visible");
      [].forEach.call(elems, function(el) {
        el.classList.remove("visible");
      });

      clearInterval(videoInterval);
    }

  }, [videoIsVisible, isVideoLoaded]);

  return (
    <Layout location={location} title={siteTitle} isHomePage={true} page={"homepage"}>
      <Seo title="Production House in Singapore | Top Video Production Company" description="Meet the top production house in Singapore, Epitome Collective. We are a new-gen video production company that produces unique pieces of creative work." extendedTitle={false} />

      <div id={"homepage-video-event"} style={{height: '2000px'}}/>

      <div className="container" id={'homepage-video-event-content'} style={{height: '2000px'}}>
        <div className="hero-section hero-section-homepage">
            <h1 class="animation" style={{'color': videoIsVisible ? 'white' : 'black'}}>
              {content.customFieldsHomepage.introText}<br/>
            <div style={{display:'inline-block'}} role={"button"} tabIndex={0}
                 onTouchStart={() => {
                   playVideo(true);
                 }}
                 onTouchCancel={() => {
                   playVideo(false);
                 }}
                 onTouchEnd={() => {
                   playVideo(false);
                 }}

                 onMouseLeave={() => {
                  playVideo(false);
                }}
                 onMouseEnter={() => {
                  playVideo(true);
                }}
            >
              <a style={{'color': videoIsVisible ? 'white' : 'black', textDecorationColor: videoIsVisible ? 'white' : 'black'}} href="/" id="title-id" className={"italic"}>{titlesList[0]}</a>
            </div><br/>
              {content.customFieldsHomepage.introTextAfterHoverable}
          </h1>
        </div>
      </div>

     {/* <FullPageVide autoplay="0" video={content.customFieldsFullscreenVideo.vimeoId} />
*/}

      <ClicableVideoSection videos={[{item: content.customFieldsFullscreenVideo.vimeoId}]} spacing={"padding-bottom-112"} />

      <div className="container container-works">
        <h2 className={"padding-bottom-24 h2-works h2-homepage animation"}>HERE'S SOME WORK WE DID FOR OUR FORWARD-THINKING CLIENTS.</h2>
      </div>

      <ol className={'work-list padding-bottom-112'}>
        {workEntry.map((post, index) => {
          // const title = post.title
          let services = '';
          post.customFieldsWork.services?.map((item) => {
            services += item.service + ' '
            return services;
          });
          return (
            <li key={post.uri}>
              <AniLink cover to={post.uri} bg="#000" direction="up" duration={1}>
                <div className={"item wp-block-image link"}>
                  <VideoEmbed video={post.customFieldsWorkLayout.thumbnailVideo} index={index} />
                  <div className={"desc"}>
                    <h3 className={"title"}>{post.customFieldsWork.client}</h3>
                    <TextRepeater text={services} />
                  </div>
                  <div className={"click-area"} id={'click-area-' + post.customFieldsWorkLayout.thumbnailVideo + "-" + index}/>
                </div>
              </AniLink>
            </li>
          )
        })}
      </ol>

      <div>
        <AniLink cover to="/portfolio" className="more-works" bg="#000" direction="up" duration={1}>See more works</AniLink>
      </div>

      <div className="padding-bottom-64">
        <div className="wrapperScrollText">
          <div className="text">
            {content.customFieldsHomepage.scrolledTextFirstLine}
          </div>
        </div>
        <div className="wrapperScrollText">
          <div className="text">
            {content.customFieldsHomepage.scrolledTextSecondLine}
          </div>
        </div>
      </div>

      <div className="container padding-bottom-96">
        <h2 className="padding-bottom-24">TOP PRODUCTION HOUSE IN SINGAPORE</h2>
        {content.content ? parse(content.content) : null}
      </div>

      <div className="container padding-bottom-64">


        <h2 className="padding-bottom-24">VIDEO PRODUCTION SERVICES</h2>

        <Accordion allowZeroExpanded="true">
          
        {offer.map((item) => {
          
          return (
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                <h3>{item.title}</h3>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                {parse(item.text)}
            </AccordionItemPanel>
        </AccordionItem>
          )
        })}

        </Accordion>
     
      </div>

      <div className="container padding-bottom-64">
        <h2 className="padding-bottom-24">Our Work</h2>
        <p>Our <a href="https://epitomecollective.com/work/">video production work portfolio</a> includes various videos that we have produced for various reputed brands. We&rsquo;ve had the honour to work with some of the biggest names in Singapore and Asia-Pacific, including the <a href="https://epitomecollective.com/portfolio/tales-of-the-cocktail/">Tales of the Cocktail</a> campaign in collaboration with the Singapore Tourism Board and Tales of the Cocktail Foundation, and <a href="https://epitomecollective.com/portfolio/under-armour-women/">Under Armour</a>.</p>
        <p>Our production house is no stranger to trying out new approaches and techniques in order to help our clients achieve their key objectives, and we are constantly experimenting with new and novel approaches to better connect with our audience. Our collaboration with renowned advertising agency, TBWA, on the <a href="https://epitomecollective.com/portfolio/puma-fast-food/">Puma Fast Food</a> campaign is just one example of our forward-thinking approach to video production, as is our work on <a href="https://epitomecollective.com/portfolio/kfc/">KFC Singapore&rsquo;s #BITEME</a> campaign for the limited-time return of their Gold Spice flavour.</p>
        <p>Whether it&rsquo;s branded content, entertainment videos, product advertisements, promotional videos, explainers, social videos or testimonials, our work exhibits how our creative team can bring life to the ideas you have for your brand.</p>
      </div>

      <div className="container padding-bottom-64">
        <h2 className="padding-bottom-24">About Epitome Collective</h2>
        <p>Epitome Collective is a trailblazing video production company based in Singapore that's all about creating visually stunning content that resonates with millennial audiences. With our bold, creative, and confident approach, we push the boundaries of video production and deliver unforgettable experiences that make a lasting impact.</p>
      </div>

      <div className="container padding-bottom-0">
        <h2 className="padding-bottom-24">Our Team</h2>
        <p>With a combined experience spanning over seven years of operating in Singapore, our talented creative production house team consists of skilled directors, producers, content creators, writers, and editors who are dedicated to their craft. We bring a wealth of knowledge and expertise to every project we undertake, ensuring exceptional results and client satisfaction.</p>
        <p>At the heart of Epitome Collective is our young, vibrant, and unapologetically creative team. We're a tight-knit community of passionate artists dedicated to producing high-quality video content that reflects today's culture and appeals to the millennial mindset. Our culture is all about collaboration and pushing the limits of what's possible in video production, never shying away from risks, and always striving for groundbreaking results.</p>
      </div>
      
      <div className="container padding-bottom-96">
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
        <div class="elfsight-app-e1a65516-5f4c-4494-9887-710bbd0d2bc2" data-elfsight-app-lazy></div>  
      </div>

      <div className="container padding-bottom-64">
        <h2 className="padding-bottom-24">FREQUENTLY ASKED QUESTIONS ABOUT PRODUCTION HOUSES</h2>

        <Accordion allowZeroExpanded="true">
          
        {faq.map((item) => {
         
          return (

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                <h3>{item.question}</h3>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                {parse(item.answer)}
            </AccordionItemPanel>
        </AccordionItem>
            
          )
        })}

        </Accordion>
     
      </div>

      <Newsletter />

    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
      site {
          siteMetadata {
              title
          }
      }
      wpPage(title: { eq: "Home" }) {
          title
          excerpt
          customFieldsFullscreenVideo {
              vimeoId
          }
          customFieldsHomepage {
              introHoverable
              introText
              introTextAfterHoverable
              scrolledTextFirstLine
              scrolledTextSecondLine
              offer {title, text}
              faq {question, answer}
          }
          content
      }
      allWpWork(
          sort: { fields: [date], order: DESC }
          limit: 4
      ) {
          nodes {
              uri
              title
              customFieldsWorkLayout {
                  thumbnailVideo
              }
              customFieldsWork {
                  client
                  services {
                      service
                  }
              }
              workTypes {
                  nodes {
                      name
                      slug
                  }
              }
              clientTypes {
                  nodes {
                      name
                      slug
                  }
              }
          }
      }
  }
`
